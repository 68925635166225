$(function(){
  //画像ポップアップ
  $('.modal-image').magnificPopup({
    type: 'image'
  });

  // 画像とテキストのポップアップ
  $('.popup').magnificPopup({
    type: 'inline',
    preloader: false,
  });
  //閉じるリンクの設定
  $('.is-close').on('click', function(e) {
    e.preventDefault();
    $.magnificPopup.close();
    console.log('close');
  });
});